<template>
  <section class="applaud-wrap" ref="applaudWrap">
    <ul class="applaud-lists" v-if="applaudObject.list.length > 0">
      <li class="applaud-item" v-for="(item, index) in applaudObject.list" :key="index">
        <section class="item-left">
          <section class="photo-wrap">
            <img :src="item.user_avatar | removeProtocolHeader" alt="" />
          </section>
        </section>
        <section class="item-right">
          <section class="item-right-up">
            <p class="name">{{ item.user_nickname }}</p>
            <p class="date">{{ item.create_time | timestampSerialize }}</p>
            <p class="applaud"><i></i>赞了</p>
          </section>
          <section class="item-right-down" v-if="talkObject[item.talk_id] !== 404">
            <section class="down-left">
              <section class="down-photo-wrap">
                <img :src="userInfo.avatar | removeProtocolHeader" alt="" />
              </section>
            </section>
            <section class="down-right">
              <section class="down-right-up">
                <p class="name">{{ userInfo.nickname }}</p>
                <p class="reply" v-html="formatEmojiBr(item.comment_content)"></p>
              </section>
              <section class="down-right-down" :class="talkObject[item.talk_id] ? '' : 'no-talk'">
                <section class="down-photo-wrap">
                  <img :src="(talkObject[item.talk_id] ? talkObject[item.talk_id].img169 : '') | removeProtocolHeader" alt="" />
                </section>
                <section class="content" @click="openTalk(item.talk_id)">
                  <p class="title">{{ talkObject[item.talk_id] ? talkObject[item.talk_id].zt_name : '' }}</p>
                  <p class="text">{{ talkObject[item.talk_id] ? talkObject[item.talk_id].zt_desc : '' }}</p>
                </section>
              </section>
            </section>
          </section>
          <section class="item-404" v-else>
            <p class="tips">
              <span class="icon"></span>
              <span class="text">抱歉，此话题已被删除</span>
            </p>
          </section>
        </section>
      </li>
    </ul>

    <section class="not-applaud" v-else>
      <img src="../../assets/image/not-applaud.png" alt="" />
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { timestampSerialize, formatEmojiBr, removeProtocolHeader } from '@/util/methods.js';
import { getTopicData } from '@/api/detail.js';
import { applaudList, readMessage } from '@/api/axios.request';

export default {
  name: 'applaud',
  data() {
    return {
      // 点赞列表
      applaudObject: {
        cursor: false,
        isEnd: false,
        scrollFlag: false,
        list: []
      },
      // 话题 id 详情
      talkObject: {}
    };
  },
  computed: {
    ...mapState(['userInfo', 'loginStatus', 'notificationNum'])
  },
  filters: { timestampSerialize, removeProtocolHeader },
  watch: {
    loginStatus: {
      handler(curVal) {
        if (curVal) {
          this.scrollEvent();
        } else {
          this.applaudObject = {
            cursor: false,
            isEnd: false,
            scrollFlag: false,
            list: []
          };
        }
      }
    },
    applaudObject: {
      handler(curVal) {
        let tempTalkObject = {};

        curVal.list.forEach((element) => {
          if (!this.talkObject[element.talk_id]) {
            tempTalkObject[element.talk_id] = false;
          }
        });

        this.talkObject = tempTalkObject;
      },
      deep: true,
      immediate: true
    },
    talkObject: {
      handler(curVal) {
        for (const key in curVal) {
          if (!curVal[key]) {
            this.getDetail(key);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    formatEmojiBr,
    // 获取点赞我的列表
    getApplaudList() {
      let _this = this;

      let { cursor, isEnd } = _this.applaudObject;

      if (isEnd) {
        return;
      }

      let params = {
        page_size: 15
      };

      if (cursor) {
        params.cursor = cursor;
      }

      applaudList(params).then((response) => {
        const { data } = response;

        if (data.code === 200) {
          data.result.list.sort((a, b) => {
            return new Date(b.create_time).getTime() - new Date(a.create_time).getTime();
          });

          if (!cursor && _this.notificationNum.like > 0) {
            readMessage({
              cursor: data.result.list[0].id,
              type: '2'
            }).then((result) => {
              const { data } = result;

              if (data.code === 200) {
                _this.$store.dispatch('getNotificationNum');
              }
            });
          }

          _this.applaudObject.cursor = data.result.cursor;
          _this.applaudObject.isEnd = data.result.is_end;
          _this.applaudObject.list.push(...data.result.list);

          _this.applaudObject.scrollFlag = false;
        } else {
          _this.msgError(data.message);
        }
      });
    },
    // 获取 话题 / 文章 详情
    getDetail(id) {
      let _this = this;

      _this.talkObject[id] = {};

      getTopicData({ uk: id }).then((result) => {
        if (Number(result.status) === 200) {
          _this.talkObject[id] = result.zhuanti;
        } else if (Number(result.status) === 404) {
          _this.talkObject[id] = 404;
        }
      });
    },
    // 打开话题页
    openTalk(talk_id) {
      let routeUrl = this.$router.resolve({
        path: `/talk/${talk_id}`
      });

      window.open(routeUrl.href, '_blank');
    },
    // 滚动事件
    scrollEvent() {
      let _this = this;

      let WH = window.innerHeight;
      let ST = document.documentElement.scrollTop;
      let applaudWrapHeight = _this.$refs.applaudWrap.offsetHeight;

      if (WH + ST + 100 >= 217 + applaudWrapHeight && !_this.applaudObject.isEnd && !_this.applaudObject.scrollFlag) {
        _this.applaudObject.scrollFlag = true;

        _this.getApplaudList();
      }
    }
  },
  created() {},
  mounted() {
    this.scrollEvent();

    window.addEventListener('scroll', this.scrollEvent);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollEvent);
  }
};
</script>

<style lang="scss" scoped>
.applaud-wrap {
  padding: 20px;

  .applaud-item {
    margin-bottom: 30px;

    .item-left {
      float: left;
      width: 40px;

      .photo-wrap {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #eee;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .item-right {
      padding-left: 5px;
      overflow: hidden;

      .item-right-up {
        position: relative;

        .name {
          font-size: 14px;
          line-height: 24px;
          font-weight: bold;
        }

        .date {
          color: #999999;
          font-size: 12px;
          line-height: 24px;
        }

        .applaud {
          color: #333333;
          height: 24px;
          font-size: 14px;
          line-height: 24px;

          i {
            display: inline-block;
            margin-top: 2px;
            margin-right: 10px;
            width: 16px;
            height: 16px;
            background: url('../../assets/image/applaud.png') no-repeat;
            vertical-align: top;
          }
        }
      }

      .item-right-down {
        margin-top: 5px;
        padding: 15px;
        background-color: #f5f6f7;

        .down-left {
          float: left;
          width: 40px;

          .down-photo-wrap {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid #eee;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }

        .down-right {
          padding-left: 5px;
          overflow: hidden;

          .down-right-up {
            .name {
              padding-top: 5px;
              color: #333333;
              font-size: 14px;
              line-height: 24px;
              font-weight: bold;
            }

            .reply {
              color: #666666;
              font-size: 14px;
              line-height: 24px;
              word-break: break-all;

              /deep/ img {
                width: 24px;
                height: 24px;
                vertical-align: top;
              }
            }
          }

          .down-right-down {
            margin-top: 5px;
            padding: 10px;
            background-color: #fff;

            .down-photo-wrap {
              float: left;
              width: 88px;
              height: 64px;

              img {
                display: block;
                width: 100%;
                height: 100%;
              }
            }

            .content {
              padding-left: 10px;
              height: 64px;
              cursor: pointer;
              overflow: hidden;

              .title {
                color: #333333;
                font-size: 14px;
                font-weight: bold;
              }

              .text {
                color: #666666;
                font-size: 12px;
                line-height: 22px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
            }

            &.no-talk {
              .down-photo-wrap {
                background-color: #eee;

                img {
                  display: none;
                }
              }

              .content {
                .title {
                  width: 200px;
                  height: 19px;
                  background-color: #eee;
                }

                .text {
                  margin-top: 4px;
                  width: 100%;
                  height: 40px;
                  background-color: #eee;
                }
              }
            }
          }
        }
      }

      .item-404 {
        margin-top: 5px;
        height: 54px;
        font-size: 0;
        text-align: center;
        background-color: #f5f6f7;

        .icon {
          display: inline-block;
          margin-top: 18px;
          margin-right: 10px;
          width: 18px;
          height: 18px;
          vertical-align: top;
          background: url('../../assets/image/item-404.png') center no-repeat;
        }

        .text {
          display: inline-block;
          height: 54px;
          line-height: 54px;
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }

  .not-applaud {
    width: 100%;
    height: 100%;

    img {
      display: block;
      margin: 150px auto 0;
    }
  }
}
</style>
